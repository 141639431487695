
const pages =[
    {
        id:0,
        to:"/",
        name:"Home",
        ariaLabel:"Go to homepage of the website",
        active:true,  

    },
    {
        id:1,
        to:"/blog/",
        name:"Blog",
        ariaLabel:"Blog",
        active:false,
    },
    {
        id:2,
        to:"/about/",
        name:"About",
        ariaLabel:"About Priyank Trivedi",
        active:true,    
    },
]
export default pages;